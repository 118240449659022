export default function Spinner (props) {

return (
<div style={{height: (props.height  ? props.height : "3vh")}} className="mx-2 d-flex justify-content-center align-items-center">
<div className="spinner-grow spinner-grow-sm" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
<div className="spinner-border spinner-border-sm" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
<div className="spinner-grow spinner-grow-sm" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
</div>
);
}
