import React, { useState, useRef, useEffect} from "react";
import API from '@aws-amplify/api';
import Spinner from './Spinner.js';


export default function Xdent(props) {


  let colorArray = ['cyan', 'Chartreuse', '#BABFD1', 'orange', 'pink', 'yellow', 'purple', 'cyan', 'Chartreuse'];
  let thresholdArray = [0.3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.2];

  let treatmentTypes=['Filling','Root Canal','Implant','Crown','Bridge','Impaction'];
 
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [gettingBoxes, setGettingBoxes] = useState(false);
  const [gettingSemSeg, setGettingSemSeg] = useState(false);


  const [errMessage, setErrMessage] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [semsegs, setSemsegs] = useState(false);

  const [showFilling, setShowFilling] = useState(true);
  const [showRootCanal, setShowRootCanal] = useState(true);
  const [showImplant, setShowImplant] = useState(true);
  const [showCrown, setShowCrown] = useState(true);
  const [showBridge, setShowBridge] = useState(true);
  const [showImpaction, setShowImpaction] = useState(false);

  const [showDecay, setShowDecay] = useState(false);
  const [showBoxes, setShowBoxes] = useState(true);
  const [fileName, setFileName] = useState('');

  const inputFile = useRef(null);
  const imgDiv = useRef(null);


  useEffect( async () => {
    const preds=predictions;
    const ss=semsegs;
    //applyPredictionsToImage(preds,ss);
  }, [showFilling, showRootCanal, showCrown, showImpaction, showBridge, showImplant]);


  useEffect( async () => {
    if (showBoxes) {
      setShowDecay(false);
    }
  }, [showBoxes]);

  useEffect( async () => {
    if (showDecay) {
      setShowBoxes(false);
    }
  }, [showDecay]);

  useEffect( async () => {
    if (isLoading) {
      setShowDecay(true);
      setShowBoxes(false);
    }
  }, [isLoading]);

  function toggleShowClasses(e) {
    
    //console.log(e.target.id);
    if (e.target.id=='filling') {
      setShowFilling(!showFilling);
    } else if (e.target.id=='rootcanal') {
      setShowRootCanal(!showRootCanal);
    } else if (e.target.id=='crown') {
      setShowCrown(!showCrown);
    } else if (e.target.id=='bridge') {
      setShowBridge(!showBridge);
    } else if (e.target.id=='impaction') {
      setShowImpaction(!showImpaction);
    } else if (e.target.id=='implant') {
      setShowImplant(!showImplant);
    }
    const preds=predictions;
    const semsegs=semsegs;
    if (preds.length > 0) {
      applyPredictionsToImage(preds,semsegs);

    }

  }



  function applyPredictionsToImage(predictions) {


    let imgTarget = document.getElementById("blah2");
    let originalImage = document.getElementById("blah0");//new Image();
    let threshold = 0.2;//(thresholdSlider.value / 100);
    let width = originalImage.width;//700;//imgTarget.width;
    let height = originalImage.height;


    if (imgTarget.src) imgTarget.src = null;


    // Create the trace canvas to draw on image
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
  
    const ctx = canvas.getContext('2d');
    ctx.drawImage(originalImage, 0, 0, width, height);
    ctx.lineWidth = 2;
    ctx.font = "8px Arial";
  
    ctx.beginPath();
    let x1, x2, y1, y2;
    let labelText, classVal, confidence, color, draw;
    for (let i = 0; i < predictions.length; i++) {
      classVal = predictions[i][0];
      if (classVal==0) {
        draw=showFilling;
      } else if (classVal==1) {
        draw=showRootCanal;
      } else if (classVal==2) {
        draw=showImplant;
      } else if (classVal==3) {
        draw=showCrown;
      } else if (classVal==4) {
        draw=showBridge;
      } else draw=showImpaction;
      ctx.beginPath();  

      if (predictions[i][1] > thresholdArray[classVal] && draw) {
        ctx.beginPath();  

        color = colorArray[classVal];

        // Set color as per class label index of colorArray and
        ctx.strokeStyle = color;
        ctx.fillStyle = color;

        // Get X/Y points from prediction.
        x1 = predictions[i][2] * width;
        y1 = predictions[i][3] * height;
        x2 = (predictions[i][4] * width) - x1;
        y2 = (predictions[i][5] * height) - y1;
  
        // Draw the box for detections.
        ctx.rect(x1, y1, x2, y2);
        ctx.stroke();
  
        ctx.fillRect(x1-1,y1-15,x2+2,15);

        // Draw the label and confidence as text
        confidence = `${Math.round(predictions[i][1] * 10000) / 100} %`;
    
        labelText = `${treatmentTypes[classVal]} - ${confidence}`;
  

        ctx.fillStyle = "black";//color;
        ctx.fillText(labelText, x1+2, y1 - 5);
  
      }
    }

  /*
    let x, y;
    let semsegsL=0;
    semsegs && semsegs.x ? semsegsL=semsegs.x.length : semsegsL=0;
    for (let i = 0; i < semsegsL; i++) {
	x=semsegs.y[i];
	y=semsegs.x[i];
	console.log(x,y);
        ctx.strokeStyle = 'cyan';
	ctx.strokeRect(x,y,1,1);
    }
*/

    const canvas2 = document.createElement('canvas');
    canvas2.width = width;
    canvas2.height = height;
  
    const ctx2 = canvas2.getContext('2d');
    ctx2.drawImage(canvas, 0, 0, width, height, 0, 0, canvas2.width, canvas2.height);

  
    let url = canvas2.toDataURL();
    imgTarget.src = url;
  }

function fileToBase64(file) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });
}

async function uploadImg(data) {
    const apiName = 'xdent';
    const path = '/api/v1/sagemaker/inference';
    const myInit = {
        body: data,
        headers: {}, // OPTIONAL
    };
    return API.post(apiName, path, myInit);
}

async function uploadImg2(data) {
    const apiName = 'semseg';
    const path = '/';
    const myInit = {
        body: data,
        headers: {}, // OPTIONAL
    };
    return API.post(apiName, path, myInit);
}

async function getSemSeg(fb64, fileRequested) {
  setGettingSemSeg(true);

  try {

    let imgTarget = document.getElementById("blah3");
    let originalImage = document.getElementById("blah0");//new Image();
    //let threshold = 0.2;//(thresholdSlider.value / 100);
    let width = originalImage.width;//700;//imgTarget.width;
    let height = originalImage.height;


    if (imgTarget.src) imgTarget.src = null;


    // Create the trace canvas to draw on image
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
  
    const ctx = canvas.getContext('2d');
    ctx.drawImage(originalImage, 0, 0, width, height);
    /*var response=await uploadImg({endpoint: 'dental-treatments-1', region:'us-east-1',imageBase64: fb64});
    if (!response.statusCode) {
      throw Error("An unknown error occured")
    } else if (response.statusCode !== 200) {
      throw Error(response.error_message);
    }

    // if no error, get the predictions and process.
    //predictions = response.predictions;
    //console.log(response.predictions);
    setPredictions(response.predictions);
    */
    var fb64s=fb64.split(',')[1].toString();
    var response2 = await uploadImg2(fb64s);
    if (!response2.statusCode) {
      throw Error("An unknown error occured")
    } else if (response2.statusCode !== 200) {
      throw Error(response2.error_message);
    } else if (response2.statusCode == 200) {
      //console.log(response2.body.b64url);
      //setSemsegs(response2.body);
      //const img3El=document.getElementById('blah3');
      imgTarget.onload = async () =>{     
        setSemsegs(true);     
        //applyPredictionsToImage(response.predictions);
      }
      //img3El.src = "data:image/jpg;base64,"+response2.body.b64url;
      var image = new Image();
      image.onload = function(){
        //console.log(image.width, image.height, width, height);
        //if (fileName == fileRequested) {

          ctx.drawImage( image, 0.15*width,0.35*height);
          let url = canvas.toDataURL();
           imgTarget.src = url;
        //}
      }
      image.src = "data:image/jpg;base64,"+response2.body.b64url;

   
    }
  } catch(e) {console.log(e)}
  setGettingSemSeg(false);

}

async function getBoxes(fb64, fileRequested) {
  setGettingBoxes(true);
  try {
    var response=await uploadImg({endpoint: 'dental-treatments-1', region:'us-east-1',imageBase64: fb64});
    if (!response.statusCode) {
      throw Error("An unknown error occured")
    } else if (response.statusCode !== 200) {
      throw Error(response.error_message);
    }

    // if no error, get the predictions and process.
    let predictions=[];
    predictions = response.predictions;
    //console.log(response.predictions);
    //if (fileName == fileRequested) {
      setPredictions(response.predictions);
      applyPredictionsToImage(response.predictions);
    //}
  } catch(e) {console.log(e)}
  setGettingBoxes(false);
}

  async function onChange(e) {
    //imgDiv.current.focus();
    setIsLoading(true);

    const file = e.target.files[0];
    //console.log(file.name);
    if (file) {
      setFileName(file.name);
      setErrMessage("");
      setPredictions([]);
      setSemsegs(false);
      setShowDecay(false);
      setShowBoxes(true);
      const fileSize = file.size;
      const fileMb = fileSize / 1024 ** 2;
      const globalWidth=1000;
      let width=globalWidth;
      let height=0;//imgEl.height*1200;
     
      if (fileMb > 4 ) {

        setErrMessage("File sizes must be less than 4 Mb, please resize and try again");
      } else {

	    const bl=URL.createObjectURL(file);
      const imgEl=document.getElementById('blah1');
      const imgEl2=document.getElementById('blah0');

	    let img = new Image();
	    img.onload = async () => {
	  	  //height=img.height*1200/img.width;
        //height=img.height;
        //width=img.width;
        // Create the trace canvas to draw on image
        const canvas = document.createElement('canvas');
        canvas.width = 0.7*globalWidth;
        canvas.height = 0.5*img.height*globalWidth/img.width;
        
        const ctx = canvas.getContext('2d');
        //context.drawImage(imageObj, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height);

        //ctx.drawImage(img, 0, 0, width, height);
        ctx.drawImage(img, 0.15*img.width, 0.35*img.height, 0.7*img.width, 0.5*img.height, 0, 0, canvas.width, canvas.height);

        let url = await canvas.toDataURL("image/jpeg");




        // input image for boxes
        const canvas2 = document.createElement('canvas');
        canvas2.width = globalWidth;
        canvas2.height = img.height*globalWidth/img.width;
        
        const ctx2 = canvas2.getContext('2d');
        //context.drawImage(imageObj, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height);

        //ctx.drawImage(img, 0, 0, width, height);
        ctx2.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas2.width, canvas2.height);

         //if (imgEl.src) imgEl.src = null;
        let url2 = await canvas2.toDataURL("image/jpeg");
        if (imgEl.src) imgEl.src = null;

        imgEl2.src = url2;
        imgEl.src = url2;
 


                //setPredictions([]);
        imgEl.onload = async () => {
          const fb64=url;//await fileToBase64(file);
          getSemSeg(fb64, file.name);  

        }

        getBoxes(url2, file.name);
        //setIsLoading(false);

        }
        img.src=bl;
//        setIsLoading(false);
       }
    }
    setIsLoading(false);
  }


  return(


	<div className="container-fluid1">

  <div className="1position-absolute 1top-10 1start-10 text-white w-100 bg-dark d-md-block d-none">
  <div className="d-flex align-items-center justify-content-between flex-wrap">
    <div className="d-flex align-items-center 1justify-content-start flex-wrap">
      <input
        type="file"
	      ref={inputFile}
        id={`imgInput`}
        name="imageURL"
        accept=".webp, .jpg, .jpeg, .png"
        onChange={onChange}
        className="card-body"
        style={{boxShadow: "none"}}
        disabled={gettingBoxes || gettingSemSeg}
      />
      {gettingSemSeg || gettingBoxes  ? <div className="d-flex align-items-center text-center text-white mx-2"><Spinner /> loading...</div> : null}
    </div>  
    <div className="d-flex flex-wrap">
      <div class="form-check form-switch mx-2">
        <input 
          class="form-check-input" 
          type="checkbox" 
          id="flexSwitchCheckChecked" 
          checked={showDecay} 
          style={{boxShadow: "none"}}
          disabled={!semsegs}
          onChange={(e)=> {
            setShowDecay(!showDecay);
            //console.log(showDecay);
            }}/>
        <label class="form-check-label" for="flexSwitchCheckChecked">Show decay</label>
      </div>
    
      <div class="form-check form-switch mx-2">
        <input 
          class="form-check-input" 
          type="checkbox" 
          id="flexSwitchCheckChecked" 
          checked={showBoxes} 
          style={{boxShadow: "none"}}
          disabled={predictions.length == 0}
          onChange={(e)=> {
            setShowBoxes(!showBoxes);
            //console.log(showDecay);
            }}/>
        <label class="form-check-label" for="flexSwitchCheckChecked">Show treatments</label>
      </div>
    </div>
    {/*<div className="form-check mx-1">
      <input         style={{boxShadow: "none"}}
        className="form-check-input" type="checkbox"  id="filling" checked={showFilling} onChange={()=>setShowFilling(!showFilling)} />
      <label className="form-check-label" htmlFor="filling">
        Filling
      </label>
    </div>
    
    <div className="form-check mx-1">
      <input         style={{boxShadow: "none"}}
        className="form-check-input" type="checkbox"  id="rootcanal" checked={showRootCanal} onChange={()=>setShowRootCanal(!showRootCanal)} />
      <label className="form-check-label" htmlFor="rootcanal">
        Root Canal
      </label>
    </div>


    <div className="form-check mx-1">
      <input         style={{boxShadow: "none"}}
        className="form-check-input" type="checkbox"  id="implant" checked={showImplant} onChange={()=>setShowImplant(!showImplant)} />
      <label className="form-check-label" htmlFor="implant">
        Implant
      </label>
    </div>

    <div className="form-check mx-1">
      <input         style={{boxShadow: "none"}}
         className="form-check-input" type="checkbox"  id="crown" checked={showCrown} onChange={()=>setShowCrown(!showCrown)} />
      <label className="form-check-label" htmlFor="crown">
        Crown
      </label>
    </div>

    <div className="form-check mx-1">
      <input  style={{boxShadow: "none"}}
        className="form-check-input" type="checkbox"  id="bridge" checked={showBridge} onChange={()=>setShowBridge(!showBridge)} />
      <label className="form-check-label" htmlFor="bridge">
        Bridge
      </label>
    </div>

    <div className="form-check mx-1">
      <input         style={{boxShadow: "none"}}
        className="form-check-input" type="checkbox"  id="impaction" checked={showImpaction} onChange={()=>setShowImpaction(!showImpaction)} />
      <label className="form-check-label" htmlFor="impaction">
        Impaction
      </label>
    </div>*/}

  </div>
</div>
    {errMessage.length > 0 ? <p className="alert alert-danger">{errMessage}</p> : null}

      {/*<div className={predictions.length == 0 && !isLoading ? "vh-100" : "none"}> 
      <img style={{width:"100%", height: "auto", display: predictions.length > 0 && semsegs ? null : "none"}} id="blah2" />
      <img style={{width:"100%", height: "auto", display:  "none"}} id="blah3" />
      <img style={{width:"100%", height: "auto", display: predictions.length > 0 && semsegs ? "none" : null}} id="blah1" />*/}

      <div className="d-md-block d-none vh-100 mh-100 position-absolute top-0 start-0 col-12 offset-0" style={{zIndex: -1}}> 
        <img style={{width:"100%", height: "100%", objectPosition: "center", objectFit: "cover", display: "none"}} id="blah0" />
        <img style={{width:"100%", height: "100%", objectPosition: "center", objectFit: "cover", display: predictions.length > 0 && showBoxes ? null : "none"}} id="blah2" />
        <img style={{width:"100%", height: "100%", objectPosition: "center", objectFit: "cover", display:  semsegs && showDecay ? null : "none"}} id="blah3" />
        <img style={{width:"100%", height: "100%", objectPosition: "center", objectFit: "cover", display: (semsegs && showDecay || predictions.length > 0 && showBoxes) ? "none" : null}} id="blah1" />
      </div>
      <div className="text-white d-block d-sm-none p-5"><p>Please use Raylyse on a large screen, or change the orientation to landscape.</p></div>
   </div>

  );
}


